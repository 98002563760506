import CookieConsent, { Cookies } from "react-cookie-consent";

function CookieConsentBanner({ setUserConsent }) {
  const handleAcceptCookies = () => {
    console.log("Cookies aceptadas");
    console.log("User consent:", Cookies.get("userConsent")); // Check if "true"
    window.gtag &&
      window.gtag("consent", "update", { analytics_storage: "granted" });
    Cookies.set("userConsent", "true", {
      expires: 365,
      sameSite: "None",
      secure: true,
    });
    setUserConsent("true");
  };

  const handleDeclineCookies = () => {
    console.log("Cookies rechazadas");
    console.log("User consent:", Cookies.get("userConsent")); // Check if "true"
    window.gtag &&
      window.gtag("consent", "update", { analytics_storage: "denied" });
    Cookies.set("userConsent", "false", {
      expires: 365,
      sameSite: "None",
      secure: true,
    });
    setUserConsent("false");
  };

  // Determinar el texto del consentimiento según la ruta
  const cookieConsentText =
    "We use cookies to improve your experience and analyze traffic. See our";

  const privacyPolicyText = "Privacy Policy";

  const privacyPolicyUrl = "/privacy-policy";

  return (
    <CookieConsent
      location='bottom'
      buttonText='Acept'
      declineButtonText='Deny'
      enableDeclineButton
      onAccept={handleAcceptCookies}
      onDecline={handleDeclineCookies}
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      declineButtonStyle={{
        color: "#ffffff",
        background: "#f44336",
        fontSize: "13px",
      }}
      cookieName='userConsent'
    >
      {cookieConsentText}{" "}
      <a href={privacyPolicyUrl} style={{ color: "#4CAF50" }}>
        {privacyPolicyText}
      </a>
      .
    </CookieConsent>
  );
}

export default CookieConsentBanner;
